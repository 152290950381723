import { merge, kebabCase } from 'lodash-es';
import 'Modules/Authentication/import/toast_authentication.tpl';

export default angular
  .module('directive.minilogin', [])

  /* @ngInject */
  .directive('miniLogin', function () {
    return {
      restrict: 'E',
      bindToController: {
        titleLogin: '@',
        titleLogout: '@',
        device: '<'
      },
      controllerAs: 'miniLoginCtrl',
      /* @ngInject */
      controller: function ($scope, $rootScope, $element, $document, $timeout, $location, $route, toastr, AccountService, LogService, AppService, RoutingService, ModalService) {
        const ctrl = this;

        ctrl.links = AccountService.getLinks();

        update();

        const $body = $(document.body);
        $body.addClass('offcanvas');

        ctrl.toggle = () => {
          toggle();
        };

        ctrl.login = event => {
          if (ctrl.isLogged && ctrl.device.mobile) {
            //toggle();
            return;
          }
          if (ctrl.isLogged) return;

          event.preventDefault();
          ModalService.show('/Template/Authentication/ModalAuthentication', null, null, 'loginModalCtrl');
        };

        ctrl.logout = () => {
          $timeout(() => {
            ctrl.isLogged = false;
          });
          $rootScope.$broadcast('showPageLoader', true);
          LogService.logout()
            .then(response => {
              if (response.status === 'OK') {
                AppService.updateParams(merge(response.VisitorContext, { IsLogged: false }));
                $rootScope.$broadcast('logUpdate', false);
                update(true);

                toastr.success('', ctrl.titleLogout, {
                  allowHtml: true,
                  extraData: {
                    template: 'Modules/Authentication/import/toast_authentication.tpl'
                  }
                });
                if ($route.current && $route.current.resolve && $route.current.resolve.authorize) {
                  $location.path('/');
                } else {
                  RoutingService.forceReload();
                }
              }
              $rootScope.$broadcast('showPageLoader', false);
            })
            .catch(error => {
              console.error(error);
            });
        };

        // Functions

        $scope.$on('userUpdate', () => {
          update(true);
        });

        $scope.$on('logUpdate', (event, value) => {
          if (value) {
            toastr.success('', ctrl.titleLogin, {
              allowHtml: true,
              extraData: {
                template: 'Modules/Authentication/import/toast_authentication.tpl'
              }
            });
          }
          update();
        });

        $scope.$on('logout', () => {
          ctrl.logout();
        });

        // Functions

        function toggle() {
          if ($body.hasClass('offcanvas-right')) {
            $body.removeClass('offcanvas-right');
            $document.off('click', onBodyClick);
          } else {
            $body.addClass('offcanvas-right');
            $document.on('click', onBodyClick);
          }
        }

        function onBodyClick(event) {
          if (!$element[0].contains(event.target)) {
            toggle();
          }
        }

        function update(refresh) {
          AppService.getParams(refresh)
            .then(params => {
              ctrl.isLogged = params.IsLogged;
              ctrl.sellerUrl = null;
              if (ctrl.isLogged) {
                if (params.Visitor.Company !== '') {
                  ctrl.visitor = params.Visitor.Company;
                } else {
                  ctrl.visitor = `${params.Visitor.FirstName} ${params.Visitor.LastName}`;
                }
                if (params.Visitor.CardType.KeyCardType === 'PERSONNEL') {
                  ctrl.sellerUrl = `/${kebabCase(params.Visitor.FirstName + params.Visitor.LastName.slice(0, 1))}-v-${params.Visitor.IDCard}`;
                }
              }
              AccountService.setManagement(ctrl);
              if (refresh) {
                $rootScope.$broadcast('cartUpdate');
              }
            })
            .catch(error => {
              console.error(error);
            });
        }
      }
    };
  });
