/*
 * angucomplete-alt v3.0.0
 * Autocomplete directive for AngularJS
 * This is a fork of Daryl Rowland's angucomplete with some extra features.
 * By Hidenari Nozaki
 * https://github.com/ghiden/angucomplete-alt
 */

export default angular
  .module('angucomplete-alt', [])

  .directive('angucompleteAlt', function ($q, $timeout) {
    // keyboard events
    const KEY_DW = 40;
    const KEY_RT = 39;
    const KEY_UP = 38;
    const KEY_LF = 37;
    const KEY_ES = 27;
    const KEY_EN = 13;
    const KEY_TAB = 9;

    const MIN_LENGTH = 3;
    const MAX_LENGTH = 524288; // the default max length per the html maxlength attribute
    const PAUSE = 500;
    const BLUR_TIMEOUT = 200;

    // string constants
    const TEMPLATE_URL = 'Modules/Shared/Autocomplete/import/autocomplete.tpl';

    function link(scope, elem, attrs) {
      const inputField = elem.find('input');
      let minlength = MIN_LENGTH;
      let searchTimer = null;
      let hideTimer;
      const validState = null;
      let httpCanceller = null;
      const httpCallInProgress = false;

      scope.currentIndex = null;
      scope.searching = false;

      scope.$on('angucomplete-alt:clearInput', (event, elementId) => {
        if (!elementId || elementId === scope.id) {
          scope.searchStr = null;
          callOrAssign();
          clearResults();
        }
      });

      scope.$on('angucomplete-alt:changeInput', (event, elementId, newval) => {
        if (!!elementId && elementId === scope.id) {
          handleInputChange(newval);
        }
      });

      scope.$on('angucomplete-alt:setFocus', event => {
        inputField.focus();
      });

      function handleInputChange(newval) {
        if (newval) {
          scope.searchStr = newval;
        }
      }

      function callOrAssign(value, str) {
        if (angular.isFunction(scope.selectedObject)) {
          scope.selectedObject(value, str);
        } else {
          scope.selectedObject = value;
        }
      }

      function setInputString(str) {
        callOrAssign({ originalObject: str });

        if (scope.clearSelected) {
          scope.searchStr = null;
        }
        clearResults();
      }

      function keyupHandler(event) {
        const which = event.which;
        if (which === KEY_LF || which === KEY_RT) {
          // do nothing
          return;
        }

        if (which === KEY_UP || which === KEY_EN) {
          event.preventDefault();
        } else if (which === KEY_DW) {
          event.preventDefault();
          if (!scope.showDropdown && scope.searchStr && scope.searchStr.length >= minlength) {
            initResults();
            scope.searching = true;
            searchTimerComplete(scope.searchStr);
          }
        } else if (which === KEY_ES) {
          clearResults();
          scope.$apply(() => {
            inputField.val(scope.searchStr);
          });
        } else {
          if (minlength === 0 && !scope.searchStr) {
            return;
          }

          if (!scope.searchStr || scope.searchStr === '') {
            scope.showDropdown = false;
          } else if (scope.searchStr.length >= minlength) {
            initResults();

            if (searchTimer) {
              $timeout.cancel(searchTimer);
            }

            scope.searching = true;

            searchTimer = $timeout(() => {
              searchTimerComplete(scope.searchStr);
            }, scope.pause);
          }

          if (validState && validState !== scope.searchStr && !scope.clearSelected) {
            scope.$apply(() => {
              callOrAssign();
            });
          }
        }
      }

      function handleOverrideSuggestions(event) {
        if (scope.overrideSuggestions && !(scope.selectedObject && scope.selectedObject[scope.matchField] === scope.searchStr)) {
          if (event) {
            event.preventDefault();
          }

          // cancel search timer
          $timeout.cancel(searchTimer);
          // cancel http request
          cancelHttpRequest();

          setInputString(scope.searchStr);
        }
      }

      function updateInputField() {
        const current = scope.results[scope.currentIndex];
        inputField.val(current[scope.matchField]);
      }

      function keydownHandler(event) {
        const which = event.which;

        if (which === KEY_EN && scope.results) {
          if (scope.currentIndex >= 0 && scope.currentIndex < scope.results.length) {
            event.preventDefault();
            scope.selectResult(scope.results[scope.currentIndex]);
          } else {
            scope.goSearch(scope.searchStr);
            if (searchTimer) {
              $timeout.cancel(searchTimer);
            }
            scope.searching = false;
            inputField.blur();
            handleOverrideSuggestions(event);
            clearResults();
            cancelHttpRequest();
          }
          scope.$apply();
        } else if (which === KEY_EN && !scope.results) {
          scope.goSearch(scope.searchStr);
          if (searchTimer) {
            $timeout.cancel(searchTimer);
          }
          scope.searching = false;
          inputField.blur();
          handleOverrideSuggestions(event);
          clearResults();
          cancelHttpRequest();
        } else if (which === KEY_DW && scope.results) {
          event.preventDefault();
          if (scope.currentIndex + 1 < scope.results.length && scope.showDropdown) {
            scope.$apply(() => {
              scope.currentIndex++;
              updateInputField();
            });
          }
        } else if (which === KEY_UP && scope.results) {
          event.preventDefault();
          if (scope.currentIndex >= 1) {
            scope.$apply(() => {
              scope.currentIndex--;
              updateInputField();
            });
          } else if (scope.currentIndex === 0) {
            scope.$apply(() => {
              scope.currentIndex = -1;
              inputField.val(scope.searchStr);
            });
          }
        } else if (which === KEY_TAB) {
          if (scope.results && scope.results.length > 0 && scope.showDropdown) {
            if (scope.currentIndex === -1 && scope.overrideSuggestions) {
              // intentionally not sending event so that it does not
              // prevent default tab behavior
              handleOverrideSuggestions();
            } else {
              if (scope.currentIndex === -1) {
                scope.currentIndex = 0;
              }
              scope.selectResult(scope.results[scope.currentIndex]);
              scope.$digest();
            }
          } else if (scope.searchStr && scope.searchStr.length > 0) {
            handleOverrideSuggestions();
          }
        } else if (which === KEY_ES) {
          // This is very specific to IE10/11 #272
          // without this, IE clears the input text
          event.preventDefault();
        }
      }

      function httpSuccessCallbackGen(str) {
        return (responseData, status, headers, config) => {
          // normalize return object from promise
          if (!status && !headers && !config && responseData.data) {
            responseData = responseData.data;
          }
          scope.searching = false;
          processResults(scope.remoteUrlResponseFormatter(responseData, str), str);
        };
      }

      function httpErrorCallback(errorRes, status, headers, config) {
        scope.searching = httpCallInProgress;

        // normalize return obejct from promise
        if (!status && !headers && !config) {
          status = errorRes.status;
        }

        // cancelled/aborted
        if (status === 0 || status === -1) {
          return;
        }
        if (console && console.error) {
          console.error('http error');
        }
      }

      function cancelHttpRequest() {
        if (httpCanceller) {
          httpCanceller.resolve();
        }
      }

      function getRemoteResults(str) {
        cancelHttpRequest();

        httpCanceller = $q.defer();

        scope.remoteApiHandler(str, httpCanceller.promise).then(httpSuccessCallbackGen(str)).catch(httpErrorCallback);
      }

      function clearResults() {
        scope.showDropdown = false;
        scope.results = [];
        if (scope.clearResults) {
          scope.clearResults();
        }
      }

      function initResults() {
        scope.showDropdown = true;
        scope.currentIndex = -1;
        scope.results = [];
      }

      function searchTimerComplete(str) {
        // Begin the search
        if (!str || str.length < minlength) {
          return;
        }
        getRemoteResults(str);
      }

      function processResults(responseData) {
        scope.results = responseData.results;

        if (responseData.count === 0) {
          scope.showDropdown = false;
        } else {
          scope.showDropdown = true;
        }
      }

      function showAll() {
        scope.searching = true;
        getRemoteResults('');
      }

      scope.onFocusHandler = () => {
        if (hideTimer) {
          $timeout.cancel(hideTimer);
        }
        if (scope.focusIn) {
          scope.focusIn();
        }
      };

      scope.hideResults = () => {
        hideTimer = $timeout(() => {
          clearResults();
          scope.showDropdown = false;
          scope.$apply(() => {
            if (scope.searchStr && scope.searchStr.length > 0) {
              inputField.val(scope.searchStr);
            }
          });
        }, BLUR_TIMEOUT);
        cancelHttpRequest();

        if (scope.focusOut) {
          scope.focusOut();
        }

        if (scope.overrideSuggestions) {
          if (scope.searchStr && scope.searchStr.length > 0 && scope.currentIndex === -1) {
            handleOverrideSuggestions();
          }
        }
      };

      scope.hoverRow = index => {
        scope.currentIndex = index;
      };

      scope.selectResult = result => {
        const str = scope.searchStr;
        if (scope.clearSelected) {
          scope.searchStr = null;
        } else {
          scope.searchStr = result[scope.matchField];
        }
        callOrAssign(result, str);
        clearResults();
      };

      scope.inputChangeHandler = str => {
        if (str.length < minlength) {
          cancelHttpRequest();
          clearResults();
        } else if (str.length === 0 && minlength === 0) {
          showAll();
        }

        if (scope.inputChanged) {
          str = scope.inputChanged(str);
        }
        return str;
      };

      scope.goSearch = () => {
        clearResults();
        scope.ctrl.goSearch(scope.searchStr);
      };

      // check min length
      if (scope.minlength && scope.minlength !== '') {
        minlength = parseInt(scope.minlength, 10);
      }

      // check pause time
      if (!scope.pause) {
        scope.pause = PAUSE;
      }

      // check clearSelected
      if (!scope.clearSelected) {
        scope.clearSelected = false;
      }

      // check override suggestions
      if (!scope.overrideSuggestions) {
        scope.overrideSuggestions = false;
      }

      scope.inputType = attrs.type ? attrs.type : 'text';

      // set max length (default to maxlength deault from html
      scope.maxlength = attrs.maxlength ? attrs.maxlength : MAX_LENGTH;

      // register events
      inputField.on('keydown', keydownHandler);
      inputField.on('keyup compositionend', keyupHandler);
    }

    return {
      restrict: 'EA',
      scope: {
        selectedObject: '=',
        remoteUrlResponseFormatter: '=',
        remoteApiHandler: '=',
        id: '@',
        type: '@',
        placeholder: '@',
        inputClass: '@',
        pause: '@',
        minlength: '@',
        clearSelected: '@',
        overrideSuggestions: '@',
        inputChanged: '=',
        focusOut: '&',
        focusIn: '&',
        fieldTabindex: '@',
        inputName: '@',
        clearResults: '=',
        matchField: '@',
        ctrl: '<'
      },
      templateUrl: (element, attrs) => attrs.templateUrl || TEMPLATE_URL,
      compile: () => link
    };
  });
