export default angular
  .module('directive.bxslider', [])

  /* @ngInject */
  .directive('bxslider', function (WindowEventsService) {
    return {
      restrict: 'A',
      scope: {
        device: '<'
      },
      link: function (scope, element, attrs) {
        if (scope.device && scope.device.mobile) return;

        const navHeight = scope.$eval(attrs.bxslider) * 2;

        import('bxslider/dist/jquery.bxslider.js').then(function () {
          WindowEventsService.listen(true, 'resize', initBxSlider, 500);

          scope.$on('$destroy', function () {
            WindowEventsService.listen(false, 'resize', initBxSlider);
            if (scope.slider) {
              scope.slider.destroySlider();
            }
          });

          initBxSlider();
        });

        function initBxSlider() {
          if (scope.slider) {
            scope.slider.destroySlider();
            element.hide().parent().css({
              height: '',
              width: ''
            });
            element.find('.bx-prev, .bx-next').remove();
          }
          if (scope.device && scope.device.mobile) return;

          setTimeout(function () {
            let nb = 5;
            nb = scope.device.md ? 5 : nb;
            nb = scope.device.sm ? 5 : nb;

            const margin = 15;
            const sc = element.closest('.d-table-cell');

            const width = Math.floor(sc.innerWidth() - 25);
            const height = Math.floor(sc.parent().height());
            //nb = Math.floor(height / width);

            element.show().parent().css({
              height,
              width
            });

            scope.slider = element.bxSlider({
              mode: 'vertical',
              minSlides: nb,
              responsive: false,
              slideMargin: margin,
              slideWidth: width,
              pager: false,
              prevSelector: '.bxslider-prev',
              nextSelector: '.bxslider-next',
              prevText: '<i class="glyphicon-client glyphicon-client-chevron-up-light"></i>',
              nextText: '<i class="glyphicon-client glyphicon-client-chevron-down-light"></i>',
              moveSlides: 1,
              infiniteLoop: false,
              preventDefaultSwipeX: false,
              preventDefaultSwipeY: true,
              controls: true,
              onSliderLoad: function () {
                element
                  .show()
                  .parent()
                  .css({
                    height: height - navHeight
                  })
                  .closest('.bxslider-container')
                  .addClass('in');
              }
            });
          }, 100);
        }
      }
    };
  });
