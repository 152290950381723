import 'import/js/directives/autocomplete/angucomplete-alt.js';
import 'Modules/Shared/Autocomplete/import/autocomplete.less';

export default angular
  .module('import.autocomplete', [])

  /* @ngInject */
  .factory('ImportAutocomplete', function ($compile, $http, $location, $timeout, RoutesService, StringService) {
    const service = {
      init
    };
    return service;

    ////////////
    function init(ctrl, scope, element, useElasticCore) {
      $compile(element.contents())(scope);

      ctrl.searchAPI = (userInputString, timeoutPromise) => (useElasticCore ? searchAPICore(userInputString, timeoutPromise) : searchAPI(userInputString, timeoutPromise));

      ctrl.getResponse = (data, str) => {
        ctrl.products = [];
        ctrl.advices = [];
        ctrl.categories = [];

        let products;
        if (useElasticCore) {
          products = data.products;
        } else {
          products = data.results;
        }

        $timeout(() => {
          ctrl.products = products.filter(o => o.KeyWebAspect !== 'Advice' && o.KeyWebAspect !== 'Recrutement' && o.KeyWebAspect !== 'Brand');
          ctrl.advices = ctrl.device.xxs ? [] : products.filter(o => o.KeyWebAspect === 'Advice');
          ctrl.categories = data.categories;

          ctrl.products.forEach(item => {
            item.designationHtml = StringService.findMatchString(item.Designation, str);
          });

          // No advices nor categories

          //ctrl.advices.forEach(item => {
          // item.designationHtml = StringService.findMatchString(item.Designation, str);
          //});

          //ctrl.categories.forEach(item => {
          // item.designationHtml = `${StringService.findMatchString(item.Designation, str)} <span class="count">(${item.TotalProducts})</span>`;
          //});

          setTimeout(() => {
            element.find('.dropdown-menu, .scroll-container').scrollTop(0);
            element.find('.dropdown-menu.with-img').trigger('scroll');
          }, 100);
        }, 100);

        return data;
      };

      ctrl.clearResults = () => {
        ctrl.products = ctrl.advices = ctrl.categories = null;
      };

      ctrl.searchCallback = selected => {
        $location.path(selected.URL);
      };

      ctrl.goSearch = str => {
        if (str && str !== '') {
          const url = `${RoutesService.get().Search.route}/${encodeURIComponent(str.split(' ').join('+')).replace(new RegExp('%2F', 'g'), '%252F')}`;
          $location.url(url);
        }
      };

      // Functions

      function resize() {
        $timeout(() => {
          const pos = element.offset().top + element.height();
          element.find('.products-menu').attr('style', `max-height: calc(100vh - ${Math.floor(pos + 55)}px)`);
          element.find('.scroll-container').attr('style', `max-height: calc(100vh - ${Math.floor(pos + 10)}px)`);
        });
      }

      function searchAPI(userInputString, timeoutPromise) {
        return $http.post(
          '/Search/AutoCompleteQuery',
          {
            QueryFullText: userInputString,
            CategoryId: '',
            NbFrom: '0',
            NbSize: '30',
            withCatalogs: ctrl.device.xxs ? false : true
          },
          { timeout: timeoutPromise }
        );
      }

      function searchAPICore(userInputString, timeoutPromise) {
        return $http.post(
          '/elssearch/query',
          {
            TextQuery: userInputString,
            From: '0',
            Size: '30'
          },
          { timeout: timeoutPromise }
        );
      }

      resize();
    }
  });
