export default angular
  .module('module.reviews', [])

  .component('productReviews', {
    bindings: {
      id: '=idProduct',
      message: '@'
    },
    /* @ngInject */
    templateUrl: function ($sce) {
      return $sce.trustAsResourceUrl('/Template/Reviews/Reviews');
    },
    controllerAs: 'reviewsCtrl',
    /* @ngInject */
    controller: function ($scope, $element, $injector) {
      const ctrl = this;

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "reviews" */ 'Modules/Reviews/import/service.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('InitReviews');
        service.initModule(ctrl, $scope, $element);
      };
    }
  })

  .directive('reviewsLink', function (ModalService) {
    return {
      restrict: 'A',
      scope: true,
      bindToController: true,
      controllerAs: 'ctrl',
      controller: function () {
        const ctrl = this;

        ctrl.openReview = function (event, id, designation) {
          event.preventDefault();
          event.stopPropagation();
          ModalService.show(
            `/Reviews/${id}?view=ModalReviews`,
            {
              designation
            },
            'lg'
          );
        };
      }
    };
  })

  /* @ngInject */
  .controller('formReviewsController', function ($injector) {
    const ctrl = this;
    ctrl.init = async (id, url, token, message) => {
      ctrl.id = id;
      ctrl.url = url;
      ctrl.token = token;
      ctrl.messageOK = message;

      const response = await import(/* webpackChunkName: "reviews" */ 'Modules/Reviews/import/service.js');
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('InitReviews');
      service.initForm(ctrl);
    };
  });
