export const googleTranslateChangeTitle = () => {
  console.log('translate');

  if ($('#google_translate_element span').eq(1).text() != '') {
    $('.google_translate_element').hide();
    $('.google_translate_element img').eq(0).remove();
    $('.google_translate_element span').eq(1).html('Fran&ccedil;ais');
    $('.google_translate_element span').eq(3).html('<span class="caret"></span>');
    $('.google_translate_element').show();
    //resizeGoogleTranslate();
  } else {
    setTimeout(googleTranslateChangeTitle, 50);
  }
};
