/*!
 * Angular responsive Module
 * Version 1.0.0
 * Uses Bootstrap 3 breakpoint sizes with HTML CSS font-family value
 * Exposes service "device" which returns true if breakpoint(s) matches.
 *
 * Modified from © Jack Tarantino <https://github.com/jacopotarantino/angular-match-media>.
 **/

export default angular
  .module('service.responsive', [])

  /* @ngInject */
  .service('DeviceService', function (WindowEventsService, $rootScope, $q) {
    this.isTouch = () => touchevents();

    this.isIOS = () => navigator.userAgent.match(/iPad|iPhone|iPod/) && !window.MSStream;

    this.wichUserAgent = function () {
      const f = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      return f;
    };

    this.wichUserAgentSafari = function () {
      const safari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
      const chrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
      if (chrome !== true) {
        return safari;
      }
    };

    this.isIE = () => {
      const _el = document.createElement('DIV');
      const d = {};

      d.isOpera = 'opera' in window; // 12-
      d.isIE = 'all' in document && 'attachEvent' in _el && !d.isOpera; // IE10-
      d.isIE9 = d.isIE && 'performance' in window && document.documentMode === 9;
      d.isIE10 = d.isIE && 'performance' in window && document.documentMode === 10;
      d.isIE11 = 'msRequestFullscreen' in _el && document.documentMode >= 11; // IE11+
      d.isIEEdge = navigator.userAgent.match(/Edge\/12\./); // IE Edge 12

      d.isIE = d.isIE11 ? true : d.isIE;
      d.isIE = d.isIEEdge ? true : d.isIE;

      d.version = null;
      d.version = d.isIE ? 12 : d.version;
      d.version = d.isIE11 ? 11 : d.version;
      d.version = d.isIE10 ? 10 : d.version;
      d.version = d.isIE9 ? 9 : d.version;

      return d;
    };

    this.isSelfscanning = () => navigator.userAgent.includes('Selfscanning') !== null;

    this.isCollabs = () => navigator.userAgent.includes('Collabs') !== null;

    const that = this;

    // Executes Angular $apply in a safe way
    const safeApply = (fn, scope) => {
      scope = scope || $rootScope;
      const phase = scope.$root.$$phase;
      if (phase === '$apply' || phase === '$digest') {
        if (fn && angular.isFunction(fn)) {
          fn();
        }
      } else {
        scope.$apply(fn);
      }
    };

    // Validates that we're getting a string or array.
    // When string: converts string(comma seperated) to an array.
    const assureList = list => {
      if (!angular.isString(list) && !angular.isArray(list)) {
        throw new Error('device requires array or comma-separated list');
      }

      return angular.isString(list) ? list.split(/\s*,\s*/) : list;
    };

    const getCurrentMatch = () => {
      if (!window.getComputedStyle) return document.documentElement.currentStyle.fontFamily.replace(/['",]/g, '');
      return window.getComputedStyle(document.documentElement, null).getPropertyValue('font-family').replace(/['",]/g, '');
    };

    // Return the actual size (it's string name defined in the rules)
    this.get = getCurrentMatch;

    this.is = list => {
      list = assureList(list);
      return list.includes(getCurrentMatch());
    };

    this.getSize = (scope, callback) => {
      WindowEventsService.listen(true, 'resize', listenerFunc, 500);

      if (scope) {
        scope.$on('$destroy', () => {
          WindowEventsService.listen(false, 'resize', listenerFunc);
        });
      }

      return that.get();

      function listenerFunc() {
        safeApply(callback(that.get()), scope);
      }
    };

    // Executes the callback function ONLY when the match differs from previous match.
    // Returns the current match truthiness.
    // The 'scope' parameter is required for cleanup reasons (destroy event).
    this.onChange = (scope, list, callback) => {
      let currentMatch = getCurrentMatch();
      list = assureList(list);
      if (!scope) {
        throw new Error('scope has to be applied for cleanup reasons. (destroy)');
      }

      WindowEventsService.listen(true, 'resize', listenerFunc, 500);

      scope.$on('$destroy', () => {
        WindowEventsService.listen(false, 'resize', listenerFunc);
      });

      return that.is(list);

      function listenerFunc() {
        const previousMatch = currentMatch;
        currentMatch = getCurrentMatch();

        const wasPreviousMatch = list.includes(previousMatch);
        const doesCurrentMatch = list.includes(currentMatch);
        if (wasPreviousMatch !== doesCurrentMatch) {
          safeApply(callback(doesCurrentMatch), scope);
        }
      }
    };

    // WebP

    this.isWebp = () => {
      const defer = $q.defer();
      checkWebp('lossy', result => {
        defer.resolve(result !== false);
      });

      return defer.promise;
    };

    function checkWebp(feature, callback) {
      const testImg = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA' /*,
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'*/
      };
      const img = new Image();
      img.onload = () => {
        const result = img.width > 0 && img.height > 0;
        callback(result);
      };
      img.onerror = () => {
        callback(false);
      };
      img.src = `data:image/webp;base64,${testImg[feature]}`;
    }

    function touchevents() {
      let bool;
      if ('ontouchstart' in window || (window.DocumentTouch && document instanceof window.DocumentTouch)) {
        bool = true;
      } else {
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        const query = ['@media (', prefixes.join('touch-enabled),('), 'heartz', ')', '{#modernizr{top:9px;position:absolute}}'].join('');
        testStyles(query, node => {
          bool = node.offsetTop === 9;
        });
      }
      return bool;
    }

    function testStyles(rule, callback) {
      const mod = 'modernizr';
      let docOverflow;
      const docElement = document.documentElement;
      const div = document.createElement('div');
      const body = getBody();

      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = `s${mod}`;

      (!body.fake ? div : body).appendChild(style);
      body.appendChild(div);

      if (style.styleSheet) {
        style.styleSheet.cssText = rule;
      } else {
        style.appendChild(document.createTextNode(rule));
      }
      div.id = mod;

      if (body.fake) {
        body.style.background = '';
        body.style.overflow = 'hidden';
        docOverflow = docElement.style.overflow;
        docElement.style.overflow = 'hidden';
        docElement.appendChild(body);
      }

      const ret = callback(div, rule);

      if (body.fake) {
        body.parentNode.removeChild(body);
        docElement.style.overflow = docOverflow;
        // eslint-disable-next-line
        docElement.offsetHeight;
      } else {
        div.parentNode.removeChild(div);
      }

      return !!ret;
    }

    function getBody() {
      let body = document.body;
      if (!body) {
        body = document.createElement('body');
        body.fake = true;
      }
      return body;
    }
  });
