import 'Modules/Shared/Autocomplete/import/autocomplete.tpl';

export default angular
  .module('directive.autocomplete', [])

  .directive('autoCompleteSearch', function () {
    return {
      restrict: 'E',
      scope: true,
      bindToController: {
        device: '<',
        textAllResults: '@',
        textCategories: '@',
        textAdvices: '@'
      },
      controllerAs: 'searchCtrl',
      /* @ngInject */
      controller: function ($scope, $element, $injector /*AppService*/) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "autocomplete" */ 'Modules/Shared/Autocomplete/import/autocomplete.js');
          $injector.loadNewModules([response.default.name, 'angucomplete-alt']);
          const service = $injector.get('ImportAutocomplete');
          //const { UseElasticCore } = await AppService.getParams();
          service.init(ctrl, $scope, $element, true);
        };
      }
    };
  });
